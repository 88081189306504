<template>
  <el-button
    v-if="showRecommendButton"
    type="plain"
    :loading="recommendButtonLoading"
    @click="recommendToLibrary"
  >
    推荐我的图书馆购买
  </el-button>
</template>

<script>
import urlSet from '@/requests/url'

export default {
  name: 'RecommendButton',
  components: {},
  props: {
    isbn: [String, Number],
  },
  data() {
    return {
      recommendButtonLoading: false,
    }
  },
  computed: {
    showRecommendButton() {
      const shortName = localStorage.getItem('shortname')
      if (!shortName || shortName === 'undefined') return false
      if (!this.isbn) return false
      return true
    },
  },
  methods: {
    async recommendToLibrary() {
      this.recommendButtonLoading = true
      let body = {
        content: this.isbn,
        inputType: 'INPUT',
      }
      const res = await this.$http.post(
        '/api/v2/user/directselect/getPreMatchedList',
        body
      )
      if (res.cxajaxrc !== 0) return
      const bookListId = res.returnvalue.booklistRuid
      const shortName = localStorage.getItem('shortname')
      const url = `${urlSet.baseUrl}/${shortName}/xs/#/selectbook/directlyselectbookresult?booklist=${bookListId}`
      window.open(url)
      this.recommendButtonLoading = false
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped></style>
