<template>
  <div class="content" v-loading="loading">
    <breadcrumb :items="currentLocation" class="bread-crumb" />
    <div class="main">
      <div class="book-info">
        <div class="book-cover">
          <div class="cover">
            <el-popover
              class="popover"
              placement="right"
              trigger="hover"
              :open-delay="1000"
            >
              <img :src="getImage(book.coverruid || book.logopath)" />
              <img slot="reference" :src="getImage(book.coverruid || book.logopath)" />
            </el-popover>
            <span class="type">{{ bookType }}</span>
          </div>
          <div class="star-box-new">
            <div class="box">
              <p>{{ book.starCount || 0 }}</p>
              <span>书评数</span>
            </div>
            <div class="box">
              <p>{{ book.userFavorCount || 0 }}</p>
              <span>收藏数</span>
            </div>
            <div class="box">
              <p>{{ book.libCount || 0 }}</p>
              <span>馆藏数</span>
            </div>
          </div>
          <!-- <div class="share">
            <el-popover trigger="hover">
              <social-share :config="shareConfig"/>
              <div slot="reference">
                <i class="share-icon"/>
                <span>分享</span>
              </div>
            </el-popover>
          </div> -->
        </div>
        <div class="info-area">
          <p v-if="book.seriestitle || book.seriesno" style="margin-bottom: 10px">
            <span
              v-if="book.seriestitle"
              class="series-title"
              @click="toSeriesList(book.seriestitle)"
            >
              {{ book.seriestitle }}
            </span>
            <span v-if="book.seriestitle && book.seriesno">，</span>
            <span v-if="book.seriesno">{{ book.seriesno }}</span>
          </p>
          <h2 class="book-name" v-html="bookTitle"></h2>
          <div
            class="sub-title name-cn"
            v-if="book.namecn || book.titlecn"
            v-html="book.namecn || book.titlecn"
          ></div>
          <BookDetailLine title="作者信息" :content="book.authorInfo" dark block />
          <p>
            <span v-if="book.publisher" class="inline-col" style="margin-right: 15px">
              <b class="bdesc">出版社：</b>
              <span>
                <span
                  style="font-size: 16px"
                  v-html="book.publisher"
                  class="publisher"
                  @click="toPublisher(book.publisher)"
                ></span>
                <!-- <span v-for="name in book.publisherBrandName" :key="name">
                  ，
                  <span v-html="name" class="child-publisher" ></span>
                </span> -->
                <template v-if="book.publisherBrandName">
                  <span class="child-publisher">, {{ book.publisherBrandName }}</span>
                </template>
              </span>
            </span>
            <span
              v-if="book.publishDateText"
              class="inline-col"
              style="margin-right: 15px"
            >
              <b class="bdesc">出版时间：</b>
              <span class="author" v-html="book.publishDateText"></span>
            </span>
            <span v-if="book.copyrightYear" class="inline-col">
              <b class="bdesc">版权年：</b>
              <span class="author" v-html="book.copyrightYear"></span>
            </span>
          </p>
          <p v-if="book.isbn">
            <BookDetailLine title="ISBN13" :content="book.isbn" dark />
            <BookDetailLine title="ISBN10" :content="book.isbn10" dark />
          </p>
          <p>
            <BookDetailLine title="载体类型及描述" :content="getBookType(book.type)" dark>
              <template v-if="book.typeInfo">({{ book.typeInfo }})</template>
            </BookDetailLine>
            <BookDetailLine title="版本" :content="book.versionStr" dark />
            <BookDetailLine title="版次" :content="book.editionStr" dark />
            <BookDetailLine title="装帧" :content="book.binding" dark>
              <template v-if="book.bindingInfo">({{ book.bindingInfo }})</template>
            </BookDetailLine>
            <BookDetailLine title="卷册号" :content="book.volumestr" dark />
          </p>
          <p>
            <BookDetailLine title="主要语种" :content="book.language1" dark>
              <template v-if="book.language1Info">({{ book.language1Info }})</template>
            </BookDetailLine>
            <BookDetailLine title="次要语种" :content="book.language2" dark>
              <template v-if="book.language2Info">({{ book.language2Info }})</template>
            </BookDetailLine>
            <span v-if="book.contentType || book.contentTypeInfo" class="mr15">
              <b class="bdesc">内容类型：</b>
              <span class="author" v-html="book.contentType"></span>
              <span class="author" v-if="book.contentTypeInfo">
                ({{ book.contentTypeInfo }})
              </span>
            </span>
            <span v-if="book.pagesInfo">
              <b class="bdesc">页码：</b>
              <span class="author" v-html="book.pagesInfo"></span>
            </span>
          </p>
          <div>
            <template v-if="book.discountPrice">
              <span class="price mr15">
                {{ book.pricelabel }}{{ $methods.getCents(book.discountPrice) }}
              </span>
              <span class="author inline-col mr15" v-if="book.discountCnyPrice">
                (约CNY{{ book.discountCnyPrice }})
              </span>
              <span class="old-price">
                <span class="title">原价：</span>
                <span class="old">
                  {{ (book.pricelabel || '') + $methods.getCents(book.price) }}
                </span>
                <span class="cny mr15" v-if="book.cnyPirce">
                  (约CNY{{ book.cnyPirce }})
                </span>
              </span>
            </template>
            <template v-else-if="book.price">
              <span class="price mr15">
                {{ (book.pricelabel || '') + $methods.getCents(book.price) }}
              </span>
              <span class="author inline-col mr15" v-if="book.cnyPirce">
                (约CNY{{ book.cnyPirce }})
              </span>
            </template>
            <span class="price-update" v-if="book.priceUpdateTime">
              <b class="bdesc">价格更新时间：</b>
              <span class="author">{{ book.priceUpdateTime }}</span>
            </span>
          </div>
          <div class="tag-table">
            <div class="tags">
              <span class="tag" v-if="book.supplystate">{{ book.supplystate }}</span>
              <span class="tag" v-if="getSaleCount != '没有现货'">
                {{ getSaleCount }}
              </span>
            </div>
          </div>
          <div class="button-review">
            <div class="buttons">
              <template v-if="buttons">
                <el-button
                  v-for="(button, index) in buttons"
                  :key="button.ruid"
                  :type="button.type"
                  :disabled="disableButton(button.text)"
                  v-show="button.text != '在线阅读'"
                  ref="actionButton"
                  @click="buttonClick(button.text, button.url, index)"
                >
                  {{ button.text }}
                </el-button>
              </template>
              <RecommendButton :isbn="book.isbn" />
            </div>
            <div class="review-info">
              <span
                v-if="
                  book.reviewState == 'manual_ok_warn' ||
                  book.reviewState == 'auto_reject'
                "
              >
                *本书可能含有法律法规禁止的内容
              </span>
              <span v-else-if="book.reviewRejectInfo || book.reviewRejectReason">
                *{{ book.reviewRejectInfo || book.reviewRejectReason }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <book-detail-table
        v-if="bindingData && bindingData.length"
        :tableData="bindingData"
      />
    </div>
    <div class="tab-box" v-sticky="true">
      <div class="info-tabs" ref="tabs">
        <span
          v-for="(tab, idx) in tabs"
          :key="tab.id"
          :class="{ 'active-tab': activeTab === idx }"
          @click="switchTab(tab.id, idx)"
        >
          {{ tab.name }}
        </span>
      </div>
    </div>
    <div class="info-container">
      <book-detail-extend :book="book" @author-filter="authorFilter" />
      <book-detail-recommend v-if="starInfo" :list-data="starInfo" />
      <book-detail-knowledge v-if="showKnowledge" :info="knowledgeData" />
      <book-detail-description v-if="hasDescription" :info="descriptionInfo" />
      <book-detail-catalog v-if="catalog" :catalog="catalog" />
      <book-detail-series v-if="series" :title="book.seriestitle" :series="series" />
      <book-detail-link v-if="urls && urls.length" :urls="urls" />
    </div>
    <add-to-list
      :showDialog="showDialog"
      :items="addItems"
      @close-export="showDialog = false"
      @save-list="saveList"
    />
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showReason"
      width="800px"
      title="请输入评论内容"
      @close="resetForm('newForm')"
    >
      <el-form
        :model="newForm"
        :rules="rules"
        ref="newForm"
        class="reason-form"
        label-width="130px"
      >
        <el-form-item label="评论内容：" prop="reason">
          <el-input
            type="textarea"
            class="input"
            v-model="newForm.reason"
            maxlength="2000"
            placeholder="请输入评论内容，不得少于50字"
            :rows="5"
          />
        </el-form-item>
        <el-form-item>
          <div class="buttons">
            <el-button type="info" @click="resetForm('newForm')">取消</el-button>
            <el-button type="primary" @click="submitForm('newForm')">确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import urlSet from '@/requests/url'
import Breadcrumb from 'components/Breadcrumb'
import AddToList from '../list/AddToList'

import BookDetailLine from './BookDetailLine.vue'
// import BookDetailAuthor from '../list/BookDetailAuthor.vue'
import BookDetailExtend from './BookDetailExtend.vue'
import BookDetailRecommend from './BookDetailRecommend.vue'
import BookDetailKnowledge from './BookDetailKnowledge.vue'
import BookDetailDescription from './BookDetailDescription.vue'
import BookDetailCatalog from './BookDetailCatalog.vue'
import BookDetailSeries from './BookDetailSeries.vue'
import BookDetailLink from './BookDetailLink.vue'
import BookDetailTable from './BookDetailTable.vue'
import RecommendButton from '../../components/RecommendButton.vue'

export default {
  name: '',
  components: {
    Breadcrumb,
    AddToList,
    BookDetailLine,
    BookDetailExtend,
    BookDetailRecommend,
    BookDetailKnowledge,
    BookDetailDescription,
    BookDetailCatalog,
    BookDetailSeries,
    BookDetailLink,
    BookDetailTable,
    RecommendButton,
  },
  data() {
    let validateReason = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入评论内容'))
      } else if (value.length < 50) {
        return callback(new Error('评论内容不能少于50字'))
      } else {
        callback()
      }
    }
    return {
      shareConfig: {
        sites: ['qzone', 'qq', 'weibo', 'wechat', 'douban'],
        wechatQrcodeHelper: '微信扫一扫分享本页面',
      },
      seriesFlag: false,
      starFlag: false,
      noImg: require('images/no-image.png'),
      bookId: '',
      recommendType: '',
      book: {},
      authorInfo: null,
      tableData: [],
      urls: [],
      series: null,
      buttons: [],
      authorList: [],
      catalog: null,
      showMore: false,
      activeTab: 0,
      shortTable: false,
      shortBook: false,
      shortBookCn: false,
      shortAuthor: false,
      shortCatalog: false,
      loading: false,
      prizeinfo: null,
      starInfo: null,
      showDialog: false,
      addItems: [],
      starUrl: '',
      showReason: false,
      newForm: {
        reason: '',
      },
      rules: {
        reason: [{ required: true, validator: validateReason, trigger: 'blur' }],
      },
      shortCata: false,
      bindingData: null,
      knowledgeData: null,
      descriptionInfo: null,
      showKnowledge: false,
    }
  },
  computed: {
    bookTitle() {
      let title = this.book.title
      const subTitle = this.book.subTitle
      if (subTitle) title += `：${subTitle}`
      return title
    },
    getSupplyOk() {
      if (this.book.main) {
        if (this.book.relatdbooks.supplyOk) {
          return '正常供货'
        } else {
          return '不可供货'
        }
      } else {
        if (this.book.supplyOk) {
          return '正常供货'
        } else {
          return '不可供货'
        }
      }
    },
    getSaleCount() {
      if (this.book.main) {
        if (this.book.relatdbooks.saleCount > 0) {
          return '有现货'
        } else {
          return '没有现货'
        }
      } else {
        if (this.book.saleCount > 0) {
          return '有现货'
        } else {
          return '没有现货'
        }
      }
    },
    hasDescription() {
      let info = this.descriptionInfo
      if (!info) {
        return false
      } else if (!info.description && !info.descriptionCn && !info.authorDescription) {
        return false
      } else {
        return true
      }
    },
    bookType() {
      // if (this.$route.query.type) return '纸+电'
      const type = this.book.type
      if (type == '1987051001') {
        return '纸书'
      } else if (type == '1987052001') {
        return '电子书'
      } else if (type == '1987054001') {
        return '纸+电'
      } else if (type == '1987053001') {
        return '数据库电子书'
      } else {
        return '未知'
      }
    },
    currentLocation() {
      let type = this.bookType
      return [
        { name: '图书', path: 'BookList' },
        { name: type + '详情', path: '' },
      ]
    },
    tabs() {
      let arr = [
        {
          id: 'extendinfo',
          name: '扩展信息',
        },
      ]
      if (this.starInfo) {
        arr.push({
          id: 'userstar',
          name: '评论信息',
        })
      }
      if (this.showKnowledge) {
        arr.push({
          id: 'libraryinfo',
          name: '知识库收录信息',
        })
      }
      if (this.hasDescription) {
        arr.push({
          id: 'bookdesc',
          name: '简介',
        })
      }
      if (this.catalog) {
        arr.push({
          id: 'catalog',
          name: '目录章节',
        })
      }
      if (this.series) {
        arr.push({
          id: 'seriesinfo',
          name: '丛书',
        })
      }
      if (this.urls && this.urls.length) {
        arr.push({
          id: 'referencelink',
          name: '参考链接',
        })
      }
      return arr
    },
  },
  methods: {
    getBookType(type) {
      if (!type) return ''
      const code = parseInt(type)
      if (!code) return type
      switch (code) {
        case 1987051001:
          return '纸书'
        case 1987052001:
          return '电子书'
        case 1987053001:
          return '数据库电子书'
        case 1987054001:
          return '纸+电'
        case 1987058001:
          return '期刊'
        default:
          return ''
      }
    },
    disableButton(text) {
      if (text.includes('已')) return true
      const state = this.book.reviewState
      // if (!state) return false
      // if (state.includes('reject')) {
      //   return true
      // } else {
      //   return false
      // }
      switch (state) {
        case 'manual_reject':
          return true
        case 'top_reject':
          return true
        case '7':
          return true
        case '9':
          return true
        default:
          return false
      }
    },
    btnStyle(text) {
      if (text.includes('已评论')) {
        return {
          background: '#a6a9ad',
          borderColor: '#a6a9ad',
          color: '#FFFFFF',
        }
      } else if (text.includes('已收藏')) {
        return {
          background: '#f4f4f5',
          borderColor: '#d3d4d6',
          color: '#909399',
        }
      } else {
        return ''
      }
    },
    getSeriesType(type) {
      if (type == '1987051001') {
        return '纸书'
      } else if (type == '1987052001') {
        return '电子书'
      } else if (type == '1987054001') {
        return '纸+电'
      } else if (type == '1987053001') {
        return '数据库电子书'
      } else {
        return '未知'
      }
    },
    showMoreSeries() {
      this.seriesFlag = !this.seriesFlag
    },
    showMoreStar() {
      this.starFlag = !this.starFlag
    },
    buttonClick(text, url, index) {
      if (text === '评论') return this.doStar(url)
      else if (text === '收藏到书单') {
        this.$refs.actionButton[index].$el.blur()
        return this.addList()
      } else if (text === '试读') return window.open(url)
    },
    doStar(url) {
      this.starUrl = url
      this.showReason = true
    },
    async starBook() {
      let userId = localStorage.getItem('userRuid')
      const body = {
        userId,
        dataId: [this.book.id],
        type: 'USER',
        reason: this.newForm.reason,
      }
      const res = await this.$http.post('/api/v1/user/personaldata/addStar', body)
      if (res.cxajaxrc != 0) return false

      this.$message.success('评论成功')
      this.showReason = false
      this.getBookDetail(this.bookId)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checkWord() //评论内容敏感词替换
        } else {
          return false
        }
      })
    },
    checkWord() {
      const body = { reason: this.newForm.reason }
      this.$http({
        url: `/api/v1/platform/libinfoBadword/review`,
        method: 'POST',
        data: body,
      }).then((res) => {
        if (res.cxajaxrc == 0) {
          this.newForm.reason = res.returnvalue.reason
          this.starBook()
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.newForm = {}
      this.ruleForm = {}
      this.showReason = false
    },
    addList() {
      const item = this.book
      this.addItems = [{ id: item.id, ruid: item.ruid }]
      this.showDialog = true
    },
    saveList() {
      this.showDialog = false
      this.selectAll = false
      this.isIndeterminate = false
      this.selected = []
      this.getBookDetail(this.bookId)
    },
    async getBookDetail(id) {
      this.loading = true
      try {
        const res = await this.$http.get(
          `/api/v1/data/getDetailByRuid2?recommendType=${this.recommendType}&ruid=${id}&reviewApproved=true`
        )
        const book = res.returnvalue
        if (book.importList && book.importList.length) {
          let userList = book.importList.map((user) => user.userName)
          book.importUser = userList.reduce((pre, cur) => `${pre},${cur}`)
        }
        if (book.priceUpdateTime)
          book.priceUpdateTime = book.priceUpdateTime.replace('T', ' ')

        if (book.contentdesc)
          this.shortBook = book.contentdesc.length > 500 ? true : false
        else this.shortBook = false
        if (!book.contentdesccn) this.shortBookCn = true
        else {
          this.shortBookCn = book.contentdesccn.length > 500 ? true : false
        }

        if (book.relatdbooks && book.relatdbooks.relatedResult.recordcount > 0) {
          this.bindingData = book.relatdbooks.relatedResult.recordlist.map((item) => {
            return item
          })
        }

        this.book = book
        // 2-14日改用新接口：
        this.buttons = book.actions.map((item) => {
          const text = item.text
          if (text === '评论') {
            item.type = 'primary'
          } else if (text === '收藏到书单') {
            item.type = 'plain'
          } else {
            item.type = 'info'
          }
          return item
        })
        let knowledgeData = {
          author: book.seniorAuthorList,
          award: book.seniorAwardList,
          series: book.seniorSeriesList,
          topic: book.topicList,
          rank: book.toplistList,
          library: book.libraryList,
        }
        this.knowledgeData = knowledgeData
        for (let key in knowledgeData) {
          if (knowledgeData[key] && knowledgeData[key].length) {
            this.showKnowledge = true
          }
        }
        if (book.seriesList && book.seriesList.length) {
          this.series = book.seriesList
        }
        if (book.linkList && book.linkList.length) {
          this.urls = book.linkList
        }
        if (book.userstarList && book.userstarList.length) {
          this.starInfo = book.userstarList
        }
        if (book.catalogList && book.catalogList.length) {
          this.catalog = book.catalogList
        }
        this.descriptionInfo = {
          description: book.contentdesc,
          descriptionCn: book.contentdesccn,
          authorDescription: book.authorDesc,
        }

        this.prizeinfo = book.prizeinfo
        this.tableData = book.libraryinfo

        const author = book.mdrelateddata.authordesc
        this.authorList = author.length ? author : null
        // if (Object.keys(book.authorDesc).length === 0) {
        //   this.authorInfo = null
        // } else {
        //   this.authorInfo = book.authorDesc
        // }
        this.authorInfo = book.authorDesc
        if (!book.authorDesc) {
          this.shortAuthor = false
        } else {
          this.shortAuthor = book.authorDesc.length > 500 ? true : false
        }

        // let tabs = book.mdrelateddata.metricgroup.filter(tab => {
        //   // return tab
        //   if (tab.ruid == 'authordesc' && (!this.authorInfo && !this.authorList)) return
        //   else return tab
      } catch (err) {
        console.log('获取书籍详情失败')
      } finally {
        this.loading = false
        // this.makeTabSticky()
      }
    },
    getType(type) {
      if (type == '1987051001') {
        return '纸书'
      } else if (type == '1987052001') {
        return '电子书'
      } else if (type == '1987054001') {
        return '纸+电'
      } else if (type == '1987053001') {
        return '数据库电子书'
      } else {
        return '未知'
      }
    },
    getImage(id) {
      if (!id) return this.noImg
      return id.substring(0, 4) === 'http'
        ? id
        : `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`
    },
    async getImageSrc(id) {
      const res = await this.$http.get(
        `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`
      )
      return res.cxajaxrc !== 0 ? this.noImg : res.returnvalue
    },
    switchTab(id, idx) {
      const el = document.getElementById(id)
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = el.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offset = 60
      document.documentElement.scrollTop = elementPosition - offset
      this.activeTab = idx
    },
    shortText(text) {
      const short = text ? text.slice(0, 500) + '...' : ''
      return short
    },
    openUrl(url) {
      window.open(url)
    },
    openBook(id) {
      const url = this.$router.resolve({
        name: 'BookDetail',
        query: { id },
      })
      window.open(url.href)
    },
    toPublisher(name) {
      let left = [
        {
          logicalop: 'and',
          relationop: 'in',
          title: '出版社',
          fieldname: 10400001,
          word: name,
        },
      ]
      let filter = {
        left,
        top: [],
        search: [],
      }
      sessionStorage.setItem('searchFilter', JSON.stringify(filter))
      this.$router.push({ path: '/BookList' })
      // this.$router.push({path:'/BookList',query:{type:2,name:name}})
    },
    toSeriesList(name) {
      let left = [
        {
          logicalop: 'and',
          fieldname: 10140001,
          relationop: 'raw',
          title: '丛书',
          word: name,
        },
      ]
      let filter = {
        left,
        top: [],
        search: [],
      }
      sessionStorage.setItem('searchFilter', JSON.stringify(filter))
      this.$router.push({ path: '/BookList' })
      // this.$router.push({path:'/BookList',query:{type:1,name:name}})
    },
    authorFilter(name) {
      let left = [
        {
          logicalop: 'and',
          fieldname: 15900011,
          relationop: 'like',
          title: '作者信息',
          word: name,
        },
      ]
      let filter = {
        left,
        top: [],
        search: [],
      }
      sessionStorage.setItem('searchFilter', JSON.stringify(filter))
      this.$router.push({ path: '/BookList' })
      // this.$router.push({path:'/BookList',query:{type:1,name:name}})
    },
    makeTabSticky() {
      const nav = this.$refs.tabs
      let offsetTop
      this.$nextTick(() => {
        offsetTop = nav.getBoundingClientRect().top
      })
      window.addEventListener('scroll', function () {
        if (window.pageYOffset >= offsetTop && nav.style.top !== 0) {
          nav.style.position = 'fixed'
          nav.style.top = 0
        } else {
          nav.style.position = 'relative'
          nav.style.top = ''
        }
      })
    },
  },
  mounted() {
    this.bookId = this.$route.query.id
    this.recommendType = 5
    this.getBookDetail(this.bookId)
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/share.min.css';
.content {
  width: 1200px;
  min-height: calc(100vh - 210px);
  margin: 0 auto;
  margin-bottom: 50px;
  word-break: break-all;
  .bread-crumb {
    margin: 10px 0;
  }
  .bdesc {
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 26px;
  }
  .author {
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    line-height: 26px;
  }
  .price-update {
    margin-left: 15px;
  }
  .main {
    padding: 30px;
    margin-bottom: 20px;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    position: relative;
    .star-box-new {
      // position: absolute;
      // right: 0;
      // top: 0;
      display: flex;
      // border: 1px solid #e6e6e6;
      flex-direction: row;
      justify-content: center;
      padding: 15px 30px;
      border: 1px solid #e1e1e1;
      border-top: none;
      .box {
        text-align: center;
        flex: 1;
        border-right: 1px solid #e1e1e1;
        &:last-of-type {
          border: none;
        }

        p {
          color: #f00;
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 3px;
        }
        span {
          color: #999;
        }
      }
    }
    .share {
      margin-top: 20px;
      cursor: pointer;
      /deep/ .social-share {
        width: 236px;
        height: 40px;
        overflow: hidden;
      }
      /deep/ .el-popover__reference {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          margin-right: 5px;
          width: 16px;
          height: 16px;
          display: inline-block;
          background-image: url('../../assets/images/share.png');
        }
        span {
          margin-left: 5px;
          font-size: 16px;
        }
      }
    }
    .star-box {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      border: 1px solid #e6e6e6;
      border-right: none;
      border-top: none;
      padding: 15px 30px;
      .box {
        text-align: center;
        &:first-child {
          padding-right: 20px;
          border-right: 1px solid #413333;
        }
        &:last-child {
          padding-left: 20px;
        }
        p {
          color: #f00;
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 3px;
        }
        span {
          color: #999;
        }
      }
    }
  }
  .book-info {
    display: flex;
    .book-cover {
      // width: 240px;
      // min-width: 240px;
      margin-right: 32px;
      .cover {
        position: relative;
        height: 300px;
        width: 300px;
        border: 1px solid #e1e1e1;
        text-align: center;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          display: inline-block;
          max-width: 298px;
          max-height: 298px;
          width: auto;
          height: auto;
          // box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .2);
        }
        .type {
          position: absolute;
          left: 0;
          bottom: 0px;
          padding: 5px 10px;
          color: #fff;
          background-color: $mainColor;
        }
      }
    }
    .info-area {
      width: 100%;
      font-size: 1.1rem;
      .series-title {
        color: $mainColor;
        text-decoration: underline;
        cursor: pointer;
      }
      h4 {
        font-size: 1.5rem;
        line-height: 3rem;
        max-width: 680px;
      }
      .book-name {
        margin-bottom: 10px;
      }
      .sub-title {
        font-size: 18px;
        margin-bottom: 15px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 21px;
        position: relative;
        max-height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        line-height: 1.8rem;
      }
      .info-row {
        :first-child {
          margin-left: 0;
        }
        > * {
          margin-left: 15px;
        }
      }
      .show-more {
        color: #3190dd;
        cursor: pointer;
        display: block;
        margin-top: 10px;
      }
      .buttons {
        button {
          border-radius: 0;
        }
        .el-button--info {
          background-color: #f0f0f0;
          border: 1px solid #f0f0f0;
          color: #666;
        }
      }
      .tips {
        margin-top: 13px;
        span {
          background: #f7f7f7;
          color: #e68422;
          padding: 3px 10px;
          margin-right: 10px;
          font-size: 14px;
        }
      }
      .tag-table {
        margin-top: 10px;
        .tags {
          span {
            font-size: 16px;
            padding: 4px 9px;
            margin-right: 10px;
            color: #3377aa;
            background-color: #eee;
          }
        }
        .table-top {
          margin-top: 20px;
          margin-bottom: 5px;
          font-size: 16px;
          color: #666;
        }
        .el-table {
          width: 808px;
          border: 1px solid #e6e6e6;
          /deep/ thead tr {
            background-color: #eee;
            .el-table__cell {
              background-color: transparent;
              color: #999;
              font-weight: normal;
              font-size: 14px;
            }
          }
          /deep/ tbody {
            .el-table__cell {
              font-size: 16px;
              color: #666;
            }
            .price {
              color: #f00;
            }
          }
        }
      }
      .price {
        color: #f00;
        font-size: 24px;
        font-weight: bold;
      }
      .publisher {
        color: #3190dd;
        text-decoration: underline;
        cursor: pointer;
      }
      .child-publisher {
        font-size: 16px;
        color: #666666;
        cursor: default;
      }
    }
  }
  .tab-box {
    // margin-top: 20px;
    .info-tabs {
      font-size: 16px;
      z-index: 99;
      width: 1200px;
      background-color: #eeeeee;
      span {
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;
      }
      .active-tab {
        background-color: $mainColor;
        color: #fff;
      }
    }
  }
}
.button-review {
  display: flex;
  margin-top: 20px;
  .review-info {
    margin-left: 15px;
    display: flex;
    align-items: center;
    max-width: 460px;
    word-break: break-all;
    span {
      color: #f00;
      font-size: 13px;
    }
  }
}
.info-container {
  border: 1px solid #e6e6e6;
  background-color: #fff;
  padding: 30px;
}
.old-price {
  margin-left: 15px;
  font-size: 16px;
  color: #666;
  .title {
    color: #333;
  }
  .old {
    text-decoration: line-through;
  }
  .cny {
    margin-left: 5px;
  }
}
.mr15 {
  margin-right: 15px;
}
</style>
