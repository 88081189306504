<template>
  <div class="info-content">
    <h3 id="seriesinfo">丛书</h3>
    <h4>{{ title }} ({{ series.length }})</h4>
    <div class="content">
      <div v-for="book in shortList" :key="book.ruid" class="book">
        <div class="image" @click="toDetail(book.ruid)">
          <img :src="getImage(book.coverRuid)"/>
          <span>{{ bookType(book.type) }}</span>
        </div>
        <p class="title" @click="toDetail(book.ruid)">{{ book.title }}</p>
        <p class="sub-title">{{ book.titleCn }}</p>
      </div>
    </div>
    <div class="extend" v-if="series.length > 5">
      <span @click="short = !short">
        {{ short ? '展开' : '收起' }}
        <i v-if="short" class="el-icon-arrow-down"></i>
        <i v-else class="el-icon-arrow-up"></i>
      </span>
    </div>
  </div>
</template>

<script>
import urlSet from '@/requests/url'

export default {
  name: '',
  props: {
    series: Array,
    title: String
  },
  data() {
    return {
      short: true,
      noImg: require('images/no-image.png'),
    }
  },
  computed: {
    shortList() {
      const list = this.series
      if (list.length <= 5) return list
      if (this.short) {
        return list.slice(0, 5)
      } else {
        return list
      }
    }
  },
  methods: {
    getImage(id) {
      if (!id) return this.noImg
      return id.substring(0, 4) === 'http' ? id : `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`
    },
    toDetail(id) {
      const url = this.$router.resolve({
        path: '/BookDetail',
        query: { id }
      })
      window.open(url.href)
    },
    bookType(type) {
      if (type == '1987051001') {
        return '纸书'
      } else if (type == '1987052001') {
        return '电子书'
      } else if (type == '1987054001') {
        return '纸+电'
      } else if (type == '1987053001') {
        return '数据库电子书'
      } else {
        return '未知'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-content {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  color: #666;
  font-size: 16px;
  line-height: 26px;
  h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 15px;
  }
  h4 {
    color: $mainColor;
    margin-bottom: 20px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    .book {
      width: 20%;
      margin-bottom: 15px;
      .image {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 200px;
        max-width: 200px;
        min-width: 200px;
        height: 200px;
        border: 1px solid #E6E6E6;
        cursor: pointer;
        img {
          max-width: 198px;
          max-height: 198px;
        }
        span {
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: $mainColor;
          padding: 1px 10px;
          color: #fff;
        }
      }
      .title {
        margin-top: 10px;
        color: #333;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
.extend {
  display: flex;
  justify-content: end;
  font-size: 16px;
  color: $mainColor;
  span {
    cursor: pointer;
  }
}
</style>
