<template>
  <div class="info-content">
    <h3 id="referencelink">参考链接</h3>
    <div class="content">
      <div v-for="item in urls" :key="item.ruid" class="item">
        <img :src="getImage(item.coverPath)" @click="openLink(item.link)"/>
      </div>
    </div>
  </div>
</template>

<script>
import urlSet from '@/requests/url'

export default {
  name: '',
  props: {
    urls: Array,
  },
  data() {
    return {
      noImg: require('images/no-image.png'),
    }
  },
  computed: {
  },
  methods: {
    openLink(link) {
      window.open(link)
    },
    getImage(id) {
      if (!id) return this.noImg
      return id.substring(0, 4) === 'http' ? id : `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`
    },
  }
}
</script>

<style lang="scss" scoped>
.info-content {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  color: #666;
  font-size: 16px;
  line-height: 26px;
  h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .content {
    display: flex;
    .item {
      margin-right: 20px;
      border: 1px solid #E6E6E6;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 212px;
      height: 80px;
      cursor: pointer;
      img {
        max-width: 210px;
        max-height: 78px;
      }
    }
  }
}
</style>
