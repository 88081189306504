<template>
  <div class="info-content">
    <h3 id="libraryinfo">知识库收录信息</h3>
    <div class="content">
      <div class="box" v-if="showBox(info.topic)">
        <p>热点专题<span>（{{ info.topic.length }}）</span>：</p>
        <div class="info" v-for="item in info.topic" :key="item.ruid">
          <i class="topic"/>
          <span v-if="item.name" class="link" @click="changePage(item.ruid, 1)">{{ item.name }}</span>
          <span v-if="item.updatetime">编辑时间：{{ item.updatetime }}</span>
        </div>
      </div>
      <div class="box" v-if="showBox(info.rank)">
        <p>图书榜单<span>（{{ info.rank.length }}）</span>：</p>
        <div class="info" v-for="item in info.rank" :key="item.toplistRuid">
          <i class="rank"/>
          <span v-if="item.toplistName" class="link" @click="changePage(item.toplistRuid, 2)">{{ item.toplistName }}<template v-if="item.subcategoryName">（{{ item.subcategoryName }}）</template></span>
          <span v-if="item.year">{{ item.year }}</span>
          <span>排名：{{ item.ranking }}</span>
        </div>
      </div>
      <div class="box" v-if="showBox(info.author)">
        <p>作者信息<span>（{{ info.author.length }}）</span>：</p>
        <div class="info" v-for="item in info.author" :key="item.seniorAuthorRuid">
          <i class="author"/>
          <span v-if="item.name" class="link" @click="changePage(item.seniorAuthorRuid, 3)">{{ item.name }}（{{ item.nameCn }}）</span>
          <span v-if="item.nationalityName">国别：{{ item.nationalityName[0] }}</span>
          <span v-if="item.bookCount">作品数量：{{ item.bookCount }}</span>
        </div>
      </div>
      <div class="box" v-if="showBox(info.award)">
        <p>奖项<span>（{{ info.award.length }}）</span>：</p>
        <div class="info" v-for="item in info.award" :key="item.seniorAwardRuid">
          <i class="award"/>
          <span v-if="item.createTime" class="award-time">{{ item.createTime.slice(0, 10) }}</span>
          <span v-if="item.seniorAwardName" class="link" @click="changePage(item.seniorAwardRuid, 4)">
            {{ item.seniorAwardName }}
            <span v-if="item.subcategoryName">（{{ item.subcategoryName }}）</span>
          </span>
          <span v-if="item.awardYear">{{ item.awardYear }}</span>
          <span v-if="item.title">{{ item.title }}</span>
        </div>
      </div>
      <div class="box" v-if="showBox(info.series)">
        <p>丛书<span>（{{ info.series.length }}）</span>：</p>
        <div class="info" v-for="item in info.series" :key="item.seniorAwardRuid">
          <i class="series"/>
          <span v-if="item.name" class="link" @click="changePage(item.ruid, 5)">{{ item.name }}</span>
          <span v-if="item.publisherName">出版社：{{ item.publisherName }}</span>
        </div>
      </div>
      <div class="box" v-if="showBox(info.library)">
        <p>馆藏信息<span>（{{ info.library.length }}）</span>：</p>
        <div class="library">
          <span class="info" v-for="item in info.library" :key="item.customercode">
          <i class="library"/>
            <span>{{ item.name }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import urlSet from '@/requests/url'

export default {
  name: '',
  props: {
    info: Object,
  },
  data() {
    return {
    }
  },
  methods: {
    showBox(list) {
      if (!list || !list.length) {
        return false
      } else {
        return true
      }
    },
    changePage(ruid, type) {
      let path
      switch (type) {
        case 1:
          path = '/hot/detail'
          break
        case 2:
          path = '/rank/detail'
          break
        case 3:
          path = '/author/detail'
          break
        case 4:
          path = '/prize/detail'
          break
        case 5:
          path = '/series/detail'
          break
      }
      let url = `${urlSet.baseUrl}/kc/#${path}?ruid=${ruid}`
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.info-content {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  font-size: 16px;
  color: #666;
  h3 {
    margin-bottom: 15px;
    color: #333;
    font-size: 18px;
  }
  .content {
    .box {
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
      p {
        margin-bottom: 10px;
        color: #333;
      }
      .info {
        line-height: 25px;
        display: flex;
        align-items: center;
        i {
          color: $mainColor;
          margin-right: 10px;
          display: inline-block;
          height: 14px;
          width: 12px;
          &.topic {
            background-image: url("~@/assets/images/knowledge/topic.png");
          }
          &.rank {
            background-image: url("~@/assets/images/knowledge/rank.png");
          }
          &.author {
            background-image: url("~@/assets/images/knowledge/author.png");
          }
          &.award {
            background-image: url("~@/assets/images/knowledge/award.png");
          }
          &.series {
            background-image: url("~@/assets/images/knowledge/series.png");
          }
          &.library {
            background-image: url("~@/assets/images/knowledge/library.png");
          }
        }
        > span {
          &::before {
            content: '，';
          }
          &:first-of-type {
            &::before {
              content: none;
            }
          }
        }
        .award-time::after {
          content: '，';
        }
        .link {
          color: #333;
          text-decoration: underline;
          cursor: pointer;
          white-space: nowrap;
          display: inline-block;
          max-width: 800px;
          overflow: hidden;
          text-overflow: ellipsis;
          &::before {
            content: none;
          }
        }
      }
      .library {
        display: flex;
        flex-wrap: wrap;
        .info {
          width: 33%;
          display: inline-block;
        }
      }
    }
  }
}
</style>
