
<template>
  <div class="table">
    <p>全部格式或版本：</p>
    <el-table
      :data="tableData"
      :row-class-name="rowClassName"
      :header-cell-style="{'text-align':'center','background':'#F0F0F0', 'color':'#333'}"
      :cell-style="{'text-align':'center'}"
      header-align="center">
      <el-table-column
        label="载体类型及描述"
        align="center">
        <template slot-scope="scope">
          <p>{{getType(scope.row.type)}}<span v-if="scope.row.typeInfo">({{scope.row.typeInfo}})</span></p>
        </template>
      </el-table-column>
        <el-table-column property="editionStr" label="版本版次装帧卷册">
          <template slot-scope="scope">
            <p>
              <span v-if="scope.row.versionStr">{{scope.row.versionStr}}<template v-if="scope.row.editionStr || scope.row.binding || scope.row.volumeNo">,</template></span>
              <span v-if="scope.row.editionStr">{{scope.row.editionStr}}<template v-if="scope.row.binding || scope.row.volumeNo">,</template></span>
              <span v-if="scope.row.binding">{{scope.row.binding}}<template v-if="scope.row.volumeNo">,</template></span>
              <span v-if="scope.row.volumeNo">{{scope.row.volumeNo}}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column property="isbn" label="ISBN">
          <template slot-scope="scope">
            <a @click="isbnLink(scope.row)">{{ scope.row.isbn }}</a>
          </template>
        </el-table-column>
        <el-table-column label="价格">
          <template slot-scope="scope">
            <p class="price">{{scope.row.priceType}}{{priceWithCents(scope.row)}}</p>
          </template>
        </el-table-column>
        <el-table-column property="publishDate" label="供货状态">
          <template slot-scope="scope">
            <p>{{scope.row.supplyOk ? '可以供货' : '不可供货'}}</p>
          </template>
        </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: '',
  components: {
  },
  props: {
    tableData: Array,
  },
  data() {
    return {
    }
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal.query.id !== oldVal.query.id) {
        location.reload()
      }
    }
  },
  methods: {
    priceWithCents(item) {
      const price = item.price
      if (!price) {
        return ''
      } else if (price % 1 === 0) {
        return price + '.00'
      } else {
        return price.toFixed(2)
      }
    },
    isbnLink(row) {
      this.$router.push({
        path: '/BookDetail',
        query: { id: row.ruid }
      })
    },
    getType(type) {
      const num = parseInt(type)
      switch (num) {
        case 1987051001:
          return '纸书'
        case 1987052001:
          return '电子书'
        case 1987053001:
          return '数据库电子书'
        case 1987054001:
          return '纸+电'
        default:
          return ''
      }
    },
    rowClassName({row}) {
      if (row.ruid == this.$route.query.id) {
        return 'active-row'
      }
    },
    toDetail(ruid) {
      const { href } = this.$router.resolve({
          path: "/BookDetail",
          query: { id: ruid }
      });
      window.open(href);
    }
  },
  mounted() {
    console.log(this.tableData);

  }
}
</script>

<style lang="scss" scoped>
a {
  color: #2f8bd6;
  text-decoration: underline;
  cursor: pointer;
}
/deep/ .el-table {
  border: 1px solid #EBEEF5;
  border-bottom: none;
}
/deep/ .el-table__body{
  min-width: 100%;
}
/deep/ .el-table__header{
  min-width: 100%;
  .cell{
    font-weight: normal !important;
    font-size: 16px !important;
    color: #666 !important;
  }

}
.alink{
  color: #5689CA;
  cursor: pointer;
  text-decoration: underline;
}
.scopeImg{
  margin: 0 auto;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #CCCCCC;
  img{
    max-width: 98px;
    max-height: 98px;
  }

}
  .table {
    margin-top: 20px;
    font-size: 16px;
    color: #666;
    .el-table {
      margin-top: 10px;
      /deep/ .el-table__header, /deep/ .el-table__body {
        width: 100% !important;
      }
      /deep/ .cell {
        font-weight: normal !important;
        font-size: 16px;
      }
      /deep/ thead tr {
        background-color: #eee;
        .el-table__cell {
          padding: 9px;
          background-color: transparent;
          color: #999;
          font-size: 16px;
        }
      }
      /deep/ tbody {
        .active-row {
          background-color: #EAF3FB !important;
          td:first-child::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 30px 30px 0 0;
            border-color: #2f8bd6 transparent transparent transparent;
          }
        }
        .el-table__cell {
          color: #666;
        }
        .price {
          color: #f00;
        }
      }
      /deep/ th {
        padding: 5px 0;
      }
      /deep/ td {
        padding: 5px 0;
      }
    }
  }
</style>
