<template>
  <el-dialog :close-on-click-modal="false" class="list-dialog" :visible.sync="show" @open="openDialog" @close="closeExport" width="800px" :title="dialogTitle" destroy-on-close>
    <el-form v-if="!newList" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
      <el-form-item label="选择书单：" prop="name">
        <el-select v-model="ruleForm.name" class="select-list">
          <el-option
            v-for="item in bookList"
            :key="item.ruid"
            :label="item.name"
            :value="item.ruid"
            placeholder="请选择书单"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="actions">
          <p>没有合适的书单，去 <span @click="toggleList('newForm')">新建</span></p>
          <div class="buttons">
            <el-button class="gray-button" @click="resetForm('ruleForm')">取消</el-button>
            <el-button type="primary" ref="submitButton" @click="submitForm('ruleForm')">确认</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <el-form v-else :model="newForm" :rules="newRules" ref="newForm" label-width="150px">
      <el-form-item label="书单名称：" prop="name">
        <el-input v-model.trim="newForm.name" maxlength="30" show-word-limit placeholder="请输入书单名称"></el-input>
      </el-form-item>
      <el-form-item label="学术标签：" prop="tag">
        <el-input v-model.trim="newForm.tag" placeholder="请输入学术标签，多个请以英文逗号分隔"></el-input>
      </el-form-item>
      <el-form-item label="公开程度：" prop="security">
        <el-select v-model="newForm.security" class="select-list">
          <el-option
            v-for="item in [{ label: '公开', value: 0 }, { label: '隐私', value: 1 }]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="书单简介：" prop="description">
        <el-input type="textarea" rows="6" maxlength="120" show-word-limit v-model.trim="newForm.description" placeholder="请输入书单简介"></el-input>
      </el-form-item>
      <el-form-item class="actions">
        <div class="buttons">
          <el-button class="gray-button" @click="toggleList('ruleForm')">取消</el-button>
          <el-button type="primary" @click="submitForm('newForm')">确认</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    items: Array,
  },
  computed: {
    dialogTitle() {
      if (this.newList) return '新建书单'
      else return '收藏到书单'
    }
  },
  watch: {
    showDialog(val) {
      this.show = val
      if (val && this.bookList && this.bookList.length) {
        this.ruleForm = {
          name: this.bookList[0].ruid
        }
      }
    }
  },
  data() {
    return {
      show: false,
      form: {},
      newForm: {
        name: '',
        description: '',
        security: 0,
      },
      ruleForm: {},
      newRules: {
        name: [
          { required: true, message: '请输入书单名称', trigger: 'blur' }
        ],
        security: [
          { required: true, message: '请选择公开程度', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请输入书单简介', trigger: 'blur' }
        ],
      },
      rules: {
        name: [
          { required: true, message: '请选择书单名称', trigger: 'change' }
        ],
      },
      newList: false,
      bookList: [],
    }
  },
  methods: {
    toggleList(name) {
      this.newList = name === 'newForm' ? true : false
      if (name === 'newForm') {
        this.newForm.security = 0
        this.newForm.description = ''
      }
      this.$nextTick(function() {
        this.$refs[name].clearValidate()
      })
    },
    async getList() {
      const res = await this.$http.post('/api/v1/customer/search/user_booklist?pagesize=999&orderfield=modifytime')
      if (res.cxajaxrc != 0) return false

      if (!res.returnvalue) return this.bookList = []
      const list = res.returnvalue.recordlist
      if (list && list.length) {
        this.bookList = list
        this.ruleForm = {
          name: this.bookList[0].ruid
        }
      }
    },
    async submitNewList() {
      let body = { name: this.newForm.name, type: 10, tag: this.newForm.tag, security: this.newForm.security, description: this.newForm.description }
      const res = await this.$http.post('/api/v1/customer/booklist/save', body)

      if (res.cxajaxrc != 0) return false
      this.$message.success('创建成功')
      this.getList()
      this.newForm.name = ''
      this.newList = false
    },
    async saveList() {
      let body = {"booklistRuid":this.ruleForm.name,"dataList":this.items}
      const res = await this.$http.post('/api/v1/customer/booklist/addData', body)
      if (res.cxajaxrc != 0) return false
      this.$message.success('收藏成功')
      this.getList()
      this.$emit('save-list')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName == 'newForm') {
            return this.submitNewList()
          } else {
            return this.saveList()
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.newForm = {
        name: '',
        description: '',
      }
      this.ruleForm = {}
      if (formName == 'newForm') {
        this.newList = false
      } else {
        this.show = false
      }
    },
    closeExport() {
      this.newForm = {
        name: '',
        description: '',
      }
      this.ruleForm = {}
      this.newList = false
      this.$emit('close-export');
    },
    openDialog() {
      this.$nextTick(() => {
        const btn = this.$refs.submitButton.$el
        btn.focus()
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
  .list-dialog {
    /deep/ .el-dialog__body {
      padding: 30px;
      .el-form-item__label {
        font-size: 20px;
      }
      .el-input__inner, .el-textarea__inner {
        border-radius: 0;
      }
      .el-form-item:last-child {
        margin-bottom: 0;
      }
    }
    .select-list {
      width: 100%;
    }
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
      p {
        font-size: 18px;
        span {
          color: $mainColor;
          cursor: pointer;
        }
      }
      .buttons {
        margin-top: 20px;
        button {
          padding: 12px 25px;
          font-size: 20px;
        }
        .gray-button {
          background-color: #F0F0F0;
          border: 1px solid #F0F0F0;
          color: #000;
        }
        /deep/ .el-form-item__content {
          display: flex;
        }
      }
    }
  }
</style>
