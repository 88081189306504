<template>
  <div class="info-content">
    <h3 id="extendinfo">扩展信息</h3>

    <h4>分类与适用信息</h4>
    <div class="info-texts">
      <p>
        <BookDetailLine title="读者群" :content="book.readerstr" block>
          <template v-if="book.readerInfo">({{ book.readerInfo }})</template>
        </BookDetailLine>
        <BookDetailLine title="内容类型" :content="book.contentType" block>
          <template v-if="book.contentTypeInfo">({{ book.contentTypeInfo }})</template>
        </BookDetailLine>
        <BookDetailLine title="主要语种" :content="book.language1">
          <template v-if="book.language1Info">({{ book.language1Info }})</template>
        </BookDetailLine>
        <BookDetailLine title="次要语种" :content="book.language2">
          <template v-if="book.language2Info">({{ book.language2Info }})</template>
        </BookDetailLine>
      </p>
      <BookDetailLine title="中图法分类" :content="clcInfo" block />
      <p
        v-if="
          book.subjectstr && book.subjectstr.length > 0 && book.subjectstr[0] != '未分类'
        "
        class="subject"
      >
        <span class="title">学科分类：</span>
        <span class="subject-list">
          <span v-for="(str, idx) in book.subjectstr" :key="str">
            {{ str }}
            <span v-if="notLastStr(book.subjectstr, idx)">;</span>
          </span>
        </span>
      </p>
      <BookDetailLine title="学科信息" :content="book.subjectInfo" block />
      <p>
        <BookDetailLine title="出版社学科大类" :content="book.publisherClass1" />
        <BookDetailLine title="出版社学科小类" :content="book.publisherClass2" />
      </p>
      <BookDetailLine title="杜威分类" :content="book.categoryNoDewey" block />
      <BookDetailLine title="美国国会分类" :content="book.categoryNoCongress" block />
      <BookDetailLine title="BISAC分类" :content="book.categoryNoBisac" block />
      <BookDetailLine title="法律分类" :content="book.categoryNoLaw" block />
      <BookDetailLine title="教材分类" :content="book.categoryNoTeaching" block />
      <!-- <BookDetailLine title="自定义分类号" :content="book.categoryNo" block/> -->
    </div>

    <h4 v-if="showUrls">参考信息</h4>
    <div class="info-texts">
      <BookDetailLine title="官网url链接" :content="book.detailUrl" link block />
      <BookDetailLine title="电子书链接" :content="book.readUrl" link block />
      <BookDetailLine title="亚马逊链接" :content="book.amazonUrl" link block />
      <BookDetailLine title="Worldcat链接" :content="book.worldcatUrl" link block />
      <BookDetailLine title="CALIS链接" :content="book.calisUrl" link block />
    </div>

    <h4>基本信息</h4>
    <div class="info-texts">
      <p>
        <BookDetailLine title="ISBN13" :content="book.isbn" />
        <BookDetailLine title="ISBN10" :content="book.isbn10" />
        <BookDetailLine
          title="EISBN"
          :content="book.eisbn.join(' ; ')"
          v-if="book.eisbn"
        />
        <BookDetailLine title="ISMN" :content="book.ismn[0]" v-if="book.ismn" />
      </p>
      <BookDetailLine title="正题名" :content="book.title" block />
      <BookDetailLine title="副题名" :content="book.subTitle" block />
      <BookDetailLine title="中文参考译名" :content="book.titlecn" block />
      <p>
        <BookDetailLine title="丛书名" :content="book.seriestitle" />
        <BookDetailLine title="丛书号" :content="book.seriesno" />
      </p>
      <BookDetailLine title="作者信息" :content="book.authorInfo" block />
      <p v-if="book.authorList">
        <span class="inline" v-for="item in book.authorList" :key="item.number">
          <span>{{ item.number == 1 ? '主要责任者' : '次要责任者' }}：</span>
          <span>{{ item.name }}</span>
          <span v-if="item.type" class="author">({{ item.type }})</span>
        </span>
      </p>
      <p>
        <BookDetailLine title="出版地" :content="book.publisherPlace" />
        <BookDetailLine title="出版社" :content="book.publisher" />
        <BookDetailLine title="子品牌" :content="book.publisherBrandName" />
        <BookDetailLine title="出版时间" :content="book.publishDateText" />
        <BookDetailLine title="版权年" :content="book.copyrightYear" />
      </p>
      <p>
        <BookDetailLine title="载体类型及描述" :content="getBookType(book.type)">
          <template v-if="book.typeInfo">({{ book.typeInfo }})</template>
        </BookDetailLine>
        <BookDetailLine title="版本" :content="book.versionStr" />
        <BookDetailLine title="版次" :content="book.editionStr" />
        <BookDetailLine title="装帧" :content="book.binding">
          <template v-if="book.bindingInfo">({{ book.bindingInfo }})</template>
        </BookDetailLine>
        <BookDetailLine title="主要语种" :content="book.language1">
          <template v-if="book.language1Info">({{ book.language1Info }})</template>
        </BookDetailLine>
        <BookDetailLine title="次要语种" :content="book.language2">
          <template v-if="book.language2Info">({{ book.language2Info }})</template>
        </BookDetailLine>
      </p>
      <p>
        <BookDetailLine title="页码" :content="book.pages" />
        <BookDetailLine title="开本" :content="book.bookSizeStr" />
        <BookDetailLine title="图表" :content="book.graph" />
        <BookDetailLine title="重量" :content="book.weight" />
        <BookDetailLine title="字数" :content="book.words" />
        <BookDetailLine title="纸本出处" :content="book.paperSource" />
        <BookDetailLine title="每箱包装数量" :content="book.quantityPerBox" />
      </p>
      <BookDetailLine title="备注" :content="book.remark" block />
    </div>
  </div>
</template>

<script>
import BookDetailLine from './BookDetailLine.vue'

export default {
  name: '',
  components: {
    BookDetailLine,
  },
  props: {
    book: Object,
  },
  computed: {
    clcInfo() {
      const clcList = this.book.clcNo
      if (this.lodash.isEmpty(clcList)) return ''
      let clcStr = clcList.join(' ; ')
      const infoList = this.book.clcstr
      if (!this.lodash.isEmpty(infoList)) {
        clcStr += ` ( ${infoList.join(' ; ')} )`
      }
      return clcStr
    },
    fullTitle() {
      const book = this.book
      let title = book.title
      if (book.volumestr) title += `, ${book.volumestr}`
      return title
    },
    showUrls() {
      const book = this.book
      return (
        book.detailUrl ||
        book.readUrl ||
        book.amazonUrl ||
        book.worldcatUrl ||
        book.calisUrl
      )
    },
    reviewState() {
      switch (this.book.reviewState) {
        case 'unreview':
          return '未审读'
        case 'auto_ok':
          return '自动审读通过'
        case 'auto_reject':
          return '待人工审读'
        case 'manual_ok':
          return '通过'
        case 'manual_ok_warn':
          return '有风险'
        case 'manual_reject':
          return '不通过'
        case 'top_reject':
          return '总署不通过'
        default:
          return '未知'
      }
    },
    pageUrl() {
      const url = window.location.href
      return url
    },
  },
  methods: {
    getBookType(type) {
      if (!type) return ''
      const code = parseInt(type)
      if (!code) return type
      switch (code) {
        case 1987051001:
          return '纸书'
        case 1987052001:
          return '电子书'
        case 1987053001:
          return '数据库电子书'
        case 1987054001:
          return '纸+电'
        case 1987058001:
          return '期刊'
        default:
          return ''
      }
    },
    notLastStr(arr, idx) {
      return !(idx === arr.length - 1)
    },
    getState(state) {
      if (state === 0) {
        return '未发布'
      } else if (state === 5) {
        return '已发布'
      } else if (state === 100) {
        return '下架'
      } else {
        return '未知'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.info-content {
  h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 15px;
  }
  h4 {
    color: #333;
    font-size: 16px;
    margin-bottom: 12px;
    &:not(:first-child) {
      margin-top: 15px;
    }
  }
  .info-texts {
    color: #666;
    font-size: 16px;
    line-height: 26px;
    .inline {
      margin-right: 20px;
    }
    .link {
      color: #5689ca;
      text-decoration: underline;
      cursor: pointer;
    }
    .subject {
      display: flex;
      .title {
        word-break: keep-all;
      }
      .subject-list {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
</style>
