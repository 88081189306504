<template>
  <div :class="block ? 'is-block' : 'is-inline'" v-show="content">
    <span :class="{ 'dark-title': dark }">{{ title }}：</span>
    <a v-if="link" :href="content" target="_blank">{{ content }}</a>
    <span v-else>{{ content }}<slot></slot></span>
  </div>
</template>

<script>
export default {
  name: 'BookDetailLine',
  props: {
    title: String,
    content: [String, Number],
    block: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
  div {
    font-size: 16px;
    line-height: 26px;
    color: #666;
    .dark-title {
      color: #333;
    }
    a {
      color: #2f8bd6;
      text-decoration: underline;
    }
  }
  .is-inline {
    display: inline-block;
    margin-right: 20px;
  }
  .is-block {
    display: block;
  }
</style>
