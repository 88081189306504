<template>
  <div class="info-content">
    <h3 id="catalog">目录章节</h3>
    <div class="catalog-box">
      <p v-for="item in shortList" :key="item.text" v-html="item.text"></p>
    </div>
    <div class="extend" v-if="catalog.length > 5">
      <span @click="short = !short">
        {{ short ? "展开" : "收起" }}
        <i v-if="short" class="el-icon-arrow-down"></i>
        <i v-else class="el-icon-arrow-up"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    catalog: Array,
  },
  data() {
    return {
      short: true,
    };
  },
  computed: {
    shortList() {
      const list = this.catalog;
      if (list.length <= 5) return list;
      if (this.short) {
        return list.slice(0, 5);
      } else {
        return list;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-content {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  color: #666;
  font-size: 16px;
  line-height: 26px;
  h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 15px;
  }
}
.extend {
  display: flex;
  justify-content: start;
  font-size: 16px;
  color: $mainColor;
  span {
    cursor: pointer;
  }
}
</style>
