<template>
  <div class="info-content">
    <div class="content-box">
      <h3 id="userstar">评论信息</h3>
      <div class="info-list">
        <div v-for="info in shortList" :key="info.username" class="info">
          <p>评论内容：{{ info.reason }}</p>
          <div class="detail">
            <span v-if="info.username">{{ info.username }}</span>
            <span v-if="info.usercustomername">{{ info.usercustomername }}</span>
            <span v-if="info.createTime">{{ info.createTime }}</span>
          </div>
        </div>
      </div>
      <div class="extend" v-if="listData.length > 3">
        <span @click="short = !short">
          {{ short ? '展开' : '收起' }}
          <i v-if="short" class="el-icon-arrow-down"></i>
          <i v-else class="el-icon-arrow-up"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    listData: Array,
  },
  data() {
    return {
      short: true,
    }
  },
  computed: {
    shortList() {
      const list = this.listData
      if (list.length <= 3) return list
      if (this.short) {
        return list.slice(0, 3)
      } else {
        return list
      }
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.info-content {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  .content-box {
    padding: 20px 30px;
    background-color: #eee;
  }
  h3 {
    color: #333;
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }
  .info-list {
    color: #666;
    font-size: 16px;
    line-height: 22px;
    .info {
      padding-bottom: 12px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      .detail {
        color: #999;
        margin-top: 5px;
        span {
          margin-right: 15px;
        }
      }
    }
  }
}
.extend {
  display: flex;
  justify-content: end;
  font-size: 16px;
  color: $mainColor;
  span {
    cursor: pointer;
  }
}
</style>
