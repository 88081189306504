<template>
  <div class="info-content">
    <div class="content-box">
      <h3 id="bookdesc">简介</h3>
      <div class="info-list">
        <div class="info" v-if="info.description || info.descriptionCn">
          <p>图书简介：</p>
          <div v-html="info.descriptionCn"></div>
          <div class="line" v-if="info.description && info.descriptionCn"></div>
          <div v-html="info.description"></div>
        </div>
        <div class="info" v-if="info.authorDescription">
          <p>作者简介：</p>
          <div v-html="info.authorDescription"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    info: Object,
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.info-content {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  .content-box {
    padding: 20px 30px;
    background-color: #eee;
  }
  h3 {
    color: #333;
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }
  .info-list {
    color: #666;
    font-size: 16px;
    line-height: 22px;
    .info {
      padding-bottom: 12px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      .line{
        border-bottom: 1px solid #ddd;
        margin: 10px 0;
      }
      p {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
